import ApiInstance from './ApiInstance.js'

export default {
    getBasicData() {
      return ApiInstance({ requiresAuth: false }).get('/index');
    },
    getSingleCourse(country,city,uni_name,level,slug) {
      return ApiInstance({ requiresAuth: false }).get(`/get-course/${country}/${city}/${uni_name}/${level}/${slug}`);
    },
    getUniCourse(uni_name) {
      return ApiInstance({ requiresAuth: false }).get(`/get-course/${uni_name}`);
    },
    getUniCampusCourse(uni_name,campus_name) {
      return ApiInstance({ requiresAuth: false }).get(`/get-course/${uni_name}/${campus_name}`);
    },
    getDisciplineCourse(discipline_name) {
      return ApiInstance({ requiresAuth: false }).get(`/get-discipline-course/${discipline_name}`);
    },
    getUniversitesWithListings() {
      return ApiInstance({ requiresAuth: false }).get(`/get-universties-with-listings`);
    },
    getCities() {
      return ApiInstance({ requiresAuth: false }).get('/cities');
    },
    searchCourses(name_data, cities_data, universties_data, page_num) {
      return ApiInstance({ requiresAuth: false }).post('/search-courses', {name: name_data, cities: cities_data, universties: universties_data, page_num});
    }
    
    
}