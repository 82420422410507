<script>
import API from "@/core/api";

export default {
    data(){
return {
    api: API.API_HOME
}
    },
        props: {
        course: {},
    },
}
</script>


<template v-if="course.level">
    <div class="card courses-desc overflow-hidden rounded shadow border-0">
              <div class="position-relative d-block overflow-hidden custom-course-image-height">
                <img
                  :src='api+`${course.images[0].image_path}`'
                  class="img-fluid rounded-top mx-auto"
                  alt=""
                />
                <div class="overlay-work bg-dark"></div>
                <router-link :to="{name: 'ListingDetail',  params: { country: course.country.toLowerCase().trim(), city : course.city.toLowerCase().trim(), uni_name: course.user_slug, level: course.level.replace(' ','-').toLowerCase().trim(), slug:course.slug }}" class="text-white h6 preview"
                  >See Details <i class="mdi mdi-chevron-right"></i
                ></router-link>
              </div>
              <p class="uni_name_on_image">
                <router-link
                  style="color: #ffffff"
                  :to="{name: 'UniCourses',  params: { uni_name: course.user_slug.toLowerCase().trim() }}"
                  >{{course.user}}</router-link
                >
              </p>

              <div class="card-body custom-card-body">
                <h5>
                  <router-link  :to="{name: 'ListingDetail',  params: { country: course.country.toLowerCase().trim(), city : course.city.toLowerCase().trim(), uni_name: course.user_slug.toLowerCase().trim(), level: course.level.replace(' ','-').toLowerCase().trim(), slug:course.slug }}" class="title text-dark course-title"
                    >{{course.title}}</router-link
                  >
                </h5>
                <span class="teacher campus-name"
                  ><router-link
                    id="teacher-box"
                  :to="{name: 'UniCampusCourses',  params: { uni_name: course.user_slug.toLowerCase().trim(), campus_name: course.campus_slug.toLowerCase().trim() }}"
                  >
                    {{course.campus}} Campus</router-link
                  ></span
                >
                <div class="fees d-flex justify-content-between">
                  <ul class="list-unstyled mb-0 numbers">
                    <li><i class="mdi mdi-school text-muted"></i> {{course.level}}  | {{course.duration}} </li>
                    <li><i class="mdi mdi-book text-muted"></i> {{course.city}} , {{course.country}}</li>
                  </ul>
                </div>
                <div class="rating course-ratings">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star h5 mb-0 text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star h5 mb-0 text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star h5 mb-0 text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star h5 mb-0 text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star h5 mb-0 text-warning"></i>
                    </li>
                  </ul>
                </div>
                <div class="row pl-2 m-0 course-buttons">
                <router-link id="read-more-box" class="btn-primary"  :to="{name: 'ListingDetail',  params: { country: course.country.toLowerCase().trim(), city : course.city.toLowerCase().trim(), uni_name: course.user_slug.toLowerCase().trim(), level: course.level.replace(' ','-').toLowerCase().trim(), slug:course.slug }}">More</router-link>
                <router-link id="apply-box" class="btn-primary"  :to="{name: 'ListingDetail',  params: { country: course.country.toLowerCase().trim(), city : course.city.toLowerCase().trim(), uni_name: course.user_slug.toLowerCase().trim(), level: course.level.replace(' ','-').toLowerCase().trim(), slug:course.slug }}">Apply</router-link>
               
             </div>
              </div>
            </div>
</template>